import Pricing from "./Pricing/Pricing";
import Schedule from "./Schedule/Schedule";

export default function InfoToday() {
	const today = new Date();
	const tomorrow = new Date();

	tomorrow.setDate(today.getDate() + 1);

	return (
		<>
			<div className={"d-flex flex-column gap-2"}>
				<Pricing date={today}/>
				<Schedule date={today}/>
			</div>

			<hr />

			<div className={"d-flex flex-column gap-2"}>
				<Pricing date={tomorrow}/>
				<Schedule date={tomorrow}/>
			</div>
		</>
	);
};
