
export default function ScheduleRow({row: schedule}) {
	let timeString = (schedule.hour.toString().length === 1 ? `0${schedule.hour}`: schedule.hour) + ":" + (schedule.startingMinute.toString().length === 1 ? `0${schedule.startingMinute}`: schedule.startingMinute);
	if (schedule.endingMinute == 60) {
		timeString += "-" + ((schedule.hour + 1).toString().length === 1 ? `0${schedule.hour + 1}`: schedule.hour + 1) + ":00";
	}
	else {
		timeString += "-" + (schedule.hour.toString().length === 1 ? `0${schedule.hour}`: schedule.hour) + ":" + (schedule.endingMinute.toString().length === 1 ? `0${schedule.endingMinute}`: schedule.endingMinute);
	}

	return (
		<tr>
			<td>{timeString}</td>
			<td>{schedule.capacity}</td>
			<td>{schedule.devices.map(value => value.name).toString()}</td>
		</tr>
	);
};
