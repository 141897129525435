import {useContext} from "react";
import {devicesContext} from "../../../context/devicesContext";
import TimeGraph from "./TimeGraph";

export default function DeviceInputs({device, setDevice}) {
	const {devices} = useContext(devicesContext);

	function onChange(evt) {
		setDevice(prevDevice => {
			const newDevice = {...prevDevice};
			newDevice[evt.target.name] = evt.target.value;
			return newDevice;
		});
	}

	const takenPins = devices.map(value => value.pin);

	return (
		<>
			<div className="mb-3">
				<label htmlFor={"deviceName" + device.id} className="form-label">Name:</label>
				<input type="text" className="form-control" id={"deviceName" + device.id}
					   placeholder="Boiler, Living room, .." name={"name"} required={true} value={device.name} onChange={onChange}/>
			</div>
			<div className="mb-3">
				<label htmlFor={"devicePower" + device.id} className="form-label">Power (kW):</label>
				<input type="number" className="form-control" id={"devicePower" + device.id} required={true} name={"power"}
					   placeholder="1.6" value={device.power} onChange={onChange}/>
			</div>
			<div className="form-check mb-3">
				<input className="form-check-input" type="checkbox" checked={device.dynamic} onChange={evt => {
					setDevice(prevState => {
						return {
							...prevState,
							dynamic: evt.target.checked
						};
					});
				}} id={"deviceDynamic" + device.id} />
				<label className="form-check-label" htmlFor={"deviceDynamic" + device.id}>Dynamic (will change the time according to avg temperature)</label>
			</div>
			<div className="mb-3">
				<label htmlFor={"deviceTime" + device.id} className="form-label">Time{device.dynamic ? ' (minutes) for 0 °C': ''}:</label>
				<input type="number" className="form-control" id={"deviceTime" + device.id}
					   placeholder="120 (2 hrs)" name={"time"} required={true} value={device.time} onChange={onChange}/>
			</div>
			{device.dynamic ? <div className="mb-3">
				<label htmlFor={"desiredTemperature" + device.id} className="form-label">Desired inside temperature (°C):</label>
				<input type="number" min={0} max={30} step={0.1} className="form-control" id={"desiredTemperature" + device.id} name={"desiredTemperature"} value={device.desiredTemperature || ''} required={true} onChange={onChange} />
			</div> : ''}
			{(device.dynamic && device.time && device.desiredTemperature) ?
				<TimeGraph point1={{
					x: device.desiredTemperature,
					y: 0
				}} point2={{
					x: 0,
					y: device.time
				}}/>: null
			}
			<div className="mb-3">
				<label htmlFor={"devicePin" + device.id} className="form-label">Pin (output number):</label>
				<select className={"form-select"} id={"devicePin" + device.id} name={"pin"} required={true} value={device.pin} onChange={onChange}>
					<option value="">Select an available pin</option>
					<option value="1" disabled={takenPins.includes(1)}>1</option>
					<option value="2" disabled={takenPins.includes(2)}>2</option>
					<option value="3" disabled={takenPins.includes(3)}>3</option>
					<option value="4" disabled={takenPins.includes(4)}>4</option>
					<option value="5" disabled={takenPins.includes(5)}>5</option>
					<option value="6" disabled={takenPins.includes(6)}>6</option>
					<option value="7" disabled={takenPins.includes(7)}>7</option>
					<option value="8" disabled={takenPins.includes(8)}>8</option>
				</select>
			</div>

		</>
	);
};
