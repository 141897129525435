import RemoveDevice from "../RemoveDevice";
import EditDevice from "../EditDevice";
import {useContext, useEffect, useState} from "react";
import {devicesContext} from "../../../../context/devicesContext";
import DeviceText from "../DeviceText";

export default function DeviceRow({row: device}) {
	const [className, setClassName] = useState("");
	const {devices, updateDevice} = useContext(devicesContext);

	function onDragStart(evt) {
		evt.dataTransfer.setData('text/plain', device.id);
		evt.dataTransfer.dropEffect = "move";

	}

	async function onDrop(evt) {
		evt.preventDefault();
		const id = evt.dataTransfer.getData('text/plain');

		try {
			const response = await fetch(process.env.REACT_APP_ENDPOINT + `/devices/${id}/priority`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					priority: device.priority
				})
			});
			if (response.status === 200) {
				const {devices} = await response.json();

				for (let device of devices) {
					updateDevice(device);
				}
			}
			else {
				//TODO set errors
			}
		}
		catch (e) {
			console.error(e);
			//TODO revert drop and set generic error message
		}
		// setDevices(prevDevices => {
		// 	const newDevices = [...prevDevices];
		// 	const firstDeviceIndex = newDevices.findIndex(d => d.id == id);
		// 	const secondDeviceIndex = newDevices.findIndex(d => d.id == device.id);
		//
		// 	newDevices[secondDeviceIndex] = newDevices[firstDeviceIndex];
		// 	newDevices[firstDeviceIndex] = device;
		// 	return newDevices;
		// });
		setClassName("");
	}

	function onDragOver(evt) {
		evt.preventDefault();
	}

	function onDragEnter(evt) {
		setClassName("table-success");
	}
	function onDragLeave(evt) {
		setClassName("");
	}
	function onDragEnd(evt) {
		setClassName("");
	}

	return (
		<tr draggable={true} onDragStart={onDragStart} onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave} onDragEnter={onDragEnter} onDragEnd={onDragEnd} className={className}>
			<td><DeviceText device={device} /></td>
			<td>
				<div className={"d-flex gap-1"}>
					<RemoveDevice device={device} />
					<EditDevice device={device} />
				</div>
			</td>
		</tr>
	);
};
