import {useContext, useEffect, useState} from "react";
import {devicesContext} from "../context/devicesContext";

export default function () {
	const {devices, setDevices} = useContext(devicesContext);

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		let isMounted = true;
		const abortController = new AbortController();

		(async () => {
			setLoading(true);
			setErrors([]);

			try {
				const response = await fetch(process.env.REACT_APP_ENDPOINT + '/devices', {
					signal: abortController.signal
				});
				if (response.status === 200) {
					const json = await response.json();
					setDevices(json.devices);
				}
				else {
					console.log(response.status);
					setErrors(["Wrong status code"]);
				}
			}
			catch (e) {
				console.error(e);
				if (e.name !== "AbortError") {
					setErrors(["Failed to connect to the server."]);
				}
			}
			finally {
				isMounted && setLoading(false);
			}
		})();

		return () => {
			isMounted = false;
			abortController.abort();
		}
	}, []);

	return {devices, loading, errors};
};
