import {useEffect, useState} from "react";

export default function usePricing (date) {
	const [pricing, setPricing] = useState([]);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [warnings, setWarnings] = useState([]);

	useEffect(() => {
		let isMounted = true;
		const abortController = new AbortController();

		(async () => {
			setLoading(true);
			setErrors([]);
			setWarnings([]);
			setPricing([]);

			try {
				const urlSearchParams = new URLSearchParams();
				let dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}`:date.getMonth() + 1}-${(date.getDate() ).toString().length === 1 ? `0${date.getDate() }`:date.getDate() }`;
				urlSearchParams.append('date', dateString);
				const response = await fetch(process.env.REACT_APP_ENDPOINT + '/pricing?' + urlSearchParams.toString(), {
					signal: abortController.signal
				});
				if (response.status === 200) {
					const json = await response.json();
					setPricing(json.pricing);
				}
				else if (response.status === 404) {
					setWarnings(["The prices for this date have not yet been fetched."]);
				}
				else {
					console.log(response.status);
					setErrors(["Wrong status code"]);
				}
			}
			catch (e) {
				console.error(e);
				if (e.name !== "AbortError") {
					setErrors(["Failed to connect to the server."]);
				}
			}
			finally {
				isMounted && setLoading(false);
			}
		})();

		return () => {
			isMounted = false;
			abortController.abort();
		}
	}, [date]);

	return {pricing, loading, errors, warnings};
};
