import {useContext, useRef} from "react";
import {devicesContext} from "../../../../context/devicesContext";

export default function RemoveDevice({device}) {
	const {removeDevice} = useContext(devicesContext);
	const modalElement = useRef();

	async function onSubmit(evt) {
		evt.preventDefault();

		try {
			const response = await fetch(process.env.REACT_APP_ENDPOINT + `/devices/${device.id}`, {
				method: "DELETE"
			});
			if (response.status === 204) {
				const modal = window.bootstrap.Modal.getOrCreateInstance(modalElement.current);
				modal.dispose();
				removeDevice(device.id);
			}
			else {
				//TODO set errors
			}
		}
		catch (e) {
			console.error(e);
			//TODO set generic error
		}
	}
	return (
		<>
			<button type="button" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target={"#removeDeviceModal" + device.id}>
				Remove
			</button>

			<div ref={modalElement} className="modal fade" id={"removeDeviceModal" + device.id} tabIndex="-1" aria-labelledby={"removeDeviceModalLabel" + device.id} aria-hidden="true">
				<div className="modal-dialog">
					<form className="modal-content" onSubmit={onSubmit}>
						<div className="modal-header">
							<h1 className="modal-title fs-5" id={"removeDeviceModalLabel" + device.id}>Remove {device.name}</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							Are you sure you want to remove {device.name}?
						</div>
						<div className="modal-footer d-flex justify-content-between">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
							<button type="submit" className="btn btn-danger">Yes</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};
