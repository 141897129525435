import {useEffect, useState} from "react";

export default function useSettings() {
	const [settings, setSettings] = useState({
		maxCapacity: 0
		// desiredTemperature: 20
	});
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [warnings, setWarnings] = useState([]);

	useEffect(() => {
		let isMounted = true;
		const abortController = new AbortController();

		(async () => {
			setLoading(true);
			setErrors([]);

			try {
				const response = await fetch(process.env.REACT_APP_ENDPOINT + '/settings', {
					signal: abortController.signal
				});
				if (response.status === 200) {
					const json = await response.json();
					setSettings(json.settings);
				}
				else {
					console.log(response.status);
					setErrors(["Wrong status code"]);
				}
			}
			catch (e) {
				console.error(e);
				if (e.name !== "AbortError") {
					setErrors(["Failed to connect to the server."]);
				}
			}
			finally {
				isMounted && setLoading(false);
			}
		})();

		return () => {
			isMounted = false;
			abortController.abort();
		}
	}, []);

	return {settings, loading, errors, setErrors, warnings};
};
