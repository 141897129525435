import Table from "../../../Table";
import TableHead from "../../../TableHead";
import TableBody from "../../../TableBody";
import PriceRow from "./PriceRow";
import usePricing from "../../../../hooks/usePricing";
import Error from "../../../Alerts/Error";
import Warning from "../../../Alerts/Warning";
import Spinner from "../../../Spinner";

export default function Pricing({date}) {
	const {pricing, loading, errors, warnings} = usePricing(date);


	return (
		<div className={"border shadow-sm p-2 rounded bg-white"}>
			<a data-bs-toggle={"collapse"} data-bs-target={"#pricingTable" + date.getTime()} href={"#"}>Pricing for {date.toLocaleDateString()}</a>

			<div id={"pricingTable" + date.getTime()} className={"collapse"}>
				<div className={"mt-2"}>
					{errors.map((value, index) => {
						return <Error key={index} message={value} />
					})}
					{warnings.map((value, index) => {
						return <Warning key={index} message={value} />
					})}
				</div>
				<Table>
					<TableHead>
						<tr>
							<th>Timeslot</th>
							<th>Price (€/MWh)</th>
						</tr>
					</TableHead>
					<tbody>
						{loading && <tr><td colSpan={2}><Spinner /></td></tr>}
						{pricing.map(value => {
							return <PriceRow row={value} key={value.id} />;
						})}
					</tbody>
				</Table>
			</div>
		</div>
	);
}
