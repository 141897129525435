import Table from "../../../Table";
import TableHead from "../../../TableHead";
import DeviceRow from "./DeviceRow";
import useDevices from "../../../../hooks/useDevices";
import Spinner from "../../../Spinner";
import Error from "../../../Alerts/Error";

export default function DeviceList() {
	const {devices, loading, errors} = useDevices();

	return (
		<div className={"border rounded p-2 shadow-sm bg-white"}>
			<div className={"mt-2"}>
				{errors.map((value, index) => {
					return <Error key={index} message={value} />
				})}
			</div>
			<Table>
				<caption className={"caption-top"}>Devices are ranked by priority (most important --> less important), you can drag and drop them to change the priority of a device</caption>
				<TableHead>
					<tr>
						<th>Device</th>
						<th>Actions</th>
					</tr>
				</TableHead>
				<tbody>
				{loading && <tr><td colSpan={2}><Spinner /></td></tr>}
				{devices.sort((a,b) => a.priority - b.priority).map(value => {
					return <DeviceRow key={value.id} row={value} />
				})}
				</tbody>
			</Table>
		</div>
	);
}
