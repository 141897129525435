import useSchedules from "../../../hooks/useSchedules";
import {useEffect, useState} from "react";
import Spinner from "../../Spinner";
import Error from "../../Alerts/Error";
import Warning from "../../Alerts/Warning";

export default function Stats () {
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState({});
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();

        (async () => {
            setLoading(true);
            setErrors([]);

            try {
                const response = await fetch(process.env.REACT_APP_ENDPOINT + '/stats', {
                    signal: abortController.signal
                });
                if (response.status === 200) {
                    const json = await response.json();
                    setStats({
                        ytd: json.ytd,
                        mtd: json.mtd
                    });
                }
                else {
                    console.log(response.status);
                    setErrors(["Wrong status code"]);
                }
            }
            catch (e) {
                console.error(e);
                if (e.name !== "AbortError") {
                    setErrors(["Failed to connect to the server."]);
                }
            }
            finally {
                isMounted && setLoading(false);
            }
        })();

        return () => {
            isMounted = false;
            abortController.abort();
        }
    }, []);

    return (
        <div className={"border rounded p-2 shadow-sm bg-white"}>
            {errors.map((value, index) => {
                return <Error key={index} message={value} />
            })}

            <div className="row">
                <div className="col">
                    <label htmlFor="ytd" className={"form-label"}>YTD (euro-cent):</label>
                    {loading ? <Spinner /> : <input type={"text"} readOnly={true} className={"form-control"} id={"ytd"} value={`${stats.ytd.totalSavings} (${stats.ytd.totalPercentage.toFixed(2)}%)`}/>}
                </div>
                <div className="col">
                    <label htmlFor="mtd" className={"form-label"}>MTD (euro-cent):</label>
                    {loading ? <Spinner /> : <input type={"text"} readOnly={true} className={"form-control"} id={"mtd"} value={`${stats.mtd.totalSavings} (${stats.mtd.totalPercentage.toFixed(2)}%)`}/>}
                </div>
            </div>
        </div>
    );
}