import TimeGraph from "../../Devices/TimeGraph";

export default function ShowGraphModal({device, schedule}) {
	return (
		<>
			<button type="button" className="btn btn-secondary btn-sm" data-bs-toggle="modal" data-bs-target={"#showGraphModal" + device.id}>
				Show time graph
			</button>

			<div className="modal fade" id={"showGraphModal" + device.id} tabIndex="-1" aria-labelledby={"showGraphModalLabel" + device.id} aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id={"showGraphModalLabel" + device.id}>Graph for {device.name}</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<TimeGraph averageTemperature={schedule.averageTemperature} point1={{
								x: device.desiredTemperature,
								y: 0
							}} point2={{
								x: 0,
								y: device.time
							}}/>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
