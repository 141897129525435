import Pricing from "../InfoToday";
import Schedule from "../InfoToday/Schedule";
import {useState} from "react";
import Stats from "./Stats";

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export default function Archive() {
	const [date, setDate] = useState(`${yesterday.getFullYear()}-${(yesterday.getMonth() + 1).toString().length === 1 ? `0${yesterday.getMonth() + 1}`:yesterday.getMonth() + 1}-${(yesterday.getDate() ).toString().length === 1 ? `0${yesterday.getDate() }`:yesterday.getDate() }`);

	return (
		<>
			<Stats />
			<hr />
			<div className={"border rounded p-2 shadow-sm bg-white"}>
				<div className="mb-3">
					<label htmlFor="archiveDate" className={"form-label"}>Date:</label>
					<input type={"date"} required={true} value={date} onChange={(evt) => {
						setDate(evt.target.value)
					}} id={"archiveDate"} className={"form-control"} />
				</div>
			</div>
			<hr />
			<div className={"d-flex flex-column gap-3"}>
				<Pricing date={new Date(date)}/>
				<Schedule date={new Date(date)}/>
			</div>
		</>
	);
};
