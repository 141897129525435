import DeviceInputs from "../DeviceInputs";
import {useContext, useEffect, useRef, useState} from "react";
import {devicesContext} from "../../../../context/devicesContext";
import Error from "../../../Alerts/Error";
import Warning from "../../../Alerts/Warning";

export default function AddDevice() {
	const addDeviceCollapse = useRef();
	const submitButton = useRef();

	const [open, setOpen] = useState(false);
	const [device, setDevice] = useState({
		id: "addDevice",
		name: '',
		power: '',
		time: '',
		pin: '',
		desiredTemperature: '',
		dynamic: true
	});
	const [errors, setErrors] = useState([]);


	const {addDevice} = useContext(devicesContext);

	useEffect(() => {
		window.bootstrap.Collapse.getOrCreateInstance(addDeviceCollapse.current, {
			toggle: false
		}); // initialise collapse

		function hiddenListener(evt) {
			setOpen(false);
		}
		function shownListener(evt) {
			setOpen(true);
		}

		const addDeviceElement = addDeviceCollapse.current;

		addDeviceElement.addEventListener('hidden.bs.collapse', hiddenListener);
		addDeviceElement.addEventListener('shown.bs.collapse', shownListener);


		return () => {
			addDeviceElement.removeEventListener('hidden.bs.collapse', hiddenListener);
			addDeviceElement.removeEventListener('shown.bs.collapse', shownListener);
		}
	}, [])
	async function onSubmit(evt) {
		evt.preventDefault();

		try {
			setErrors([]);
			const response = await fetch(process.env.REACT_APP_ENDPOINT + "/devices", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(device)
			});
			if (response.status === 201) {
				const {device} = await response.json();
				addDevice(device);
			}
			else if (response.status === 421) {
				const {errors} = await response.json();
				setErrors(errors);
			}
			else {
				setErrors(["Wrong status code"]);
			}
		}
		catch (e) {
			console.error(e);
			setErrors(["Failed to connect to the server."]);
		}
	}

	function openCollapse() {
		const collapse = window.bootstrap.Collapse.getOrCreateInstance(addDeviceCollapse.current, {
			toggle: false
		});

		if (!open) {
			collapse.show();
		}
		else {
			submitButton.current.click(); // if already opened submit form keeping events
		}
	}

	function hideCollapse() {
		const collapse = window.bootstrap.Collapse.getOrCreateInstance(addDeviceCollapse.current, {
			toggle: false
		});
		collapse.hide();
	}

	return (
		<div className={"border rounded p-2 shadow-sm bg-white"}>
			<form onSubmit={onSubmit} ref={addDeviceCollapse} className={"collapse"}>
				<div className={"mt-2"}>
					{errors.map((value, index) => {
						return <Error key={index} message={value} />
					})}
				</div>
				<DeviceInputs device={device} setDevice={setDevice}/>
				<button type={"submit"} ref={submitButton} className={"d-none"} />
			</form>

			<div className="d-flex gap-2">
				{open && <button type={"button"} className={"btn btn-secondary"} onClick={hideCollapse}>Hide</button>}
				<button className={"btn btn-success w-100"} onClick={openCollapse}>Add device</button>
			</div>
		</div>
	);
};
