import TableHead from "../../../TableHead";
import ScheduleDevice from "./ScheduleDevice";
import DeviceText from "../../Devices/DeviceText";
import ShowGraphModal from "./ShowGraphModal";

export default function ScheduleParameters({schedule}) {
	return (
		<>
			<TableHead>
				<tr>
					<th colSpan={3}>The following are the parameters on this specific date for which the schedule was generated</th>
				</tr>
			</TableHead>
			<tbody>
			<tr>
				<td>Average outside temperature:</td>
				<td colSpan={2}>{schedule.averageTemperature} °C</td>
			</tr>
			<tr>
				<td>Max capacity:</td>
				<td colSpan={2}>{schedule.maxCapacity} kW</td>
			</tr>
			{schedule.devices.length > 0 &&
				<>
					<tr>
						<td rowSpan={schedule.devices.length}>Devices:</td>
						<td colSpan={2}>
							<div className={"d-flex justify-content-between"}>
								<div>
									<DeviceText device={{
										...schedule.devices[0],
										time: schedule.devices[0].realTime
									}} />
								</div>
								{schedule.devices[0].dynamic && <ShowGraphModal device={schedule.devices[0]} schedule={schedule} />}
							</div>

						</td>
					</tr>
					{schedule.devices.length > 1 &&
						schedule.devices.slice(1).map(device => {
							return <ScheduleDevice device={device} key={device.id} schedule={schedule}/>;
						})
					}
				</>
			}
			</tbody>
		</>
	);
};
