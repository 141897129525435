import {createContext, useState} from "react";

export const devicesContext = createContext({
	devices: [],
	setDevices: (devices) => {},
	addDevice: (device) => {},
	updateDevice: (device) => {},
	removeDevice: (deviceId) => {}
});

export function DevicesProvider ({children}) {
	const [devices, setDevices] = useState([]);

	function addDevice(device) {
		setDevices(prevState => {
			return [...prevState, device];
		});
	}
	function updateDevice(device) {
		setDevices(prevState => {
			const newState = [...prevState];
			newState[newState.findIndex(value => value.id === device.id)] = device;
			return newState;
		});
	}
	function removeDevice(deviceId) {
		setDevices(prevState => {
			return [...prevState].filter(value => value.id !== deviceId);
		});
	}

	return (
		<devicesContext.Provider value={{
			devices,
			setDevices,
			addDevice,
			removeDevice,
			updateDevice
		}}>
			{children}
		</devicesContext.Provider>
	);
}
