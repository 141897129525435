import {NavLink} from "react-router-dom";

const iStyle = {
    fontSize: 'x-large'
};
const spanStyle = {
    fontSize: 'small'
};

export default function Navbar() {
    let defaultClassName = "nav-link p-0 flex-column d-flex align-items-center";

    return (
        <nav className="navbar navbar-expand navbar-dark p-0 shadow-sm border-top bg-dark">
            <div className="container-fluid p-0">
                <ul className="navbar-nav justify-content-between w-100">
                    <li className="nav-item w-100">
                        <NavLink to="schedule"
                             className={({isActive}) => {
                                 return isActive ? defaultClassName + " active": defaultClassName;
                             }}>
                            <i className="bi bi-hourglass-split" style={iStyle}></i>
                            <span style={spanStyle}>Schedule</span>
                        </NavLink>
                    </li>
                    <li className="nav-item w-100 border-start border-end">
                        <NavLink to={"devices"}
                             className={({isActive}) => {
                                 return isActive ? defaultClassName + " active": defaultClassName;
                             }}>
                            <i className="bi bi-outlet" style={iStyle}></i>
                            <span style={spanStyle}>Devices</span>
                        </NavLink>
                    </li>
                    <li className="nav-item w-100 border-end">
                        <NavLink to="archive"
                                 className={({isActive}) => {
                                     return isActive ? defaultClassName + " active": defaultClassName;
                                 }}>
                            <i className="bi bi-archive" style={iStyle}></i>
                            <span style={spanStyle}>Archive</span>
                        </NavLink>
                    </li>
                    <li className="nav-item w-100">
                        <NavLink to="settings"
                                 className={({isActive}) => {
                                     return isActive ? defaultClassName + " active": defaultClassName;
                                 }}>
                            <i className="bi bi-sliders" style={iStyle}></i>
                            <span style={spanStyle}>Settings</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    );
}
