import DeviceList from "./DeviceList";
import AddDevice from "./AddDevice";
import {DevicesProvider} from "../../../context/devicesContext";

export default function Devices() {
	return (
		<DevicesProvider>
			<AddDevice />
			<hr />
			<DeviceList />
		</DevicesProvider>
	);
}
