import Table from "../../../Table";
import ScheduleRow from "./ScheduleRow";
import TableHead from "../../../TableHead";
import TotalCosts from "./TotalCosts";
import useSchedules from "../../../../hooks/useSchedules";
import Spinner from "../../../Spinner";
import Error from "../../../Alerts/Error";
import Warning from "../../../Alerts/Warning";
import ScheduleParameters from "./ScheduleParameters";

export default function Schedule({date}) {
	const {schedule, loading, errors, warnings} = useSchedules(date);

	return (
		<div className={"border shadow-sm p-2 rounded bg-white"}>
			<a data-bs-toggle={"collapse"} data-bs-target={"#scheduleTable" + date.getTime()} href={"#"}>Schedule for {date.toLocaleDateString()}</a>

			<div id={"scheduleTable" + date.getTime()} className={"collapse"}>
				<div className={"mt-2"}>
					{errors.map((value, index) => {
						return <Error key={index} message={value} />
					})}
					{warnings.map((value, index) => {
						return <Warning key={index} message={value} />
					})}
				</div>

				{loading && <Spinner />}

				{schedule &&
					<Table>
						<TableHead>
							<tr>
								<th>Timeslot</th>
								<th>Capacity (kW)</th>
								<th>Devices</th>
							</tr>
						</TableHead>
						<tbody>
						{loading && <tr><td colSpan={3}><Spinner /></td></tr>}
						{schedule.times.map(value => {
							return <ScheduleRow row={value} key={value.id} />;
						})}
						</tbody>
						<tbody>
						<TotalCosts total={schedule.costs} />
						<tr>
							<th colSpan={3}>Total savings (euro-cent): {(schedule.savings / 10).toFixed(2).toLocaleString()} ({((schedule.savings / (schedule.savings + schedule.costs) * 10000) / 100).toFixed(2).toLocaleString()}%)</th>
						</tr>
						</tbody>
						<ScheduleParameters schedule={schedule} />
					</Table>
				}
			</div>
		</div>
	);
}
