import Devices from "./components/pages/Devices";
import Schedule from "./components/pages/InfoToday";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Navbar from "./components/Navbar";
import Settings from "./components/pages/Settings";
import InfoToday from "./components/pages/InfoToday/InfoToday";
import Archive from "./components/pages/Archive";

function App() {
  return (
    <div className={"bg-light vh-100 p-3"}>
        <BrowserRouter>
            <div className={"fixed-bottom"}>
                <Navbar/>
            </div>

            <div className="">
                <Routes>
                    <Route path={'/devices'} element={<Devices />} />
                    <Route path={'/schedule'} element={<InfoToday />} />
                    <Route path={'/archive'} element={<Archive />} />
                    <Route path={'/settings'} element={<Settings />} />
                    <Route path={"*"} element={<Navigate to={'/devices'} replace={true} />}/>
                </Routes>
            </div>

            {/*In order te make sure the navbar doesn't overlap with the content*/}
            <div style={{visibility: "hidden"}}>
                <Navbar />
            </div>
        </BrowserRouter>
    </div>
  );
}

export default App;
