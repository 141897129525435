
export default function PriceRow({row: price}) {
	const date = new Date(price.dateTime);
	const timeSlot = `${date.getHours().toString().length === 1 ? `0${date.getHours()}`:date.getHours()}:00-${(date.getHours() + 1).toString().length === 1 ? `0${date.getHours() + 1}`:date.getHours() + 1}:00`;

	return (
		<tr>
			<td>{timeSlot}</td>
			<td>{price.price.toLocaleString()}</td>
		</tr>
	);
};
