import DeviceText from "../../Devices/DeviceText";
import ShowGraphModal from "./ShowGraphModal";

export default function ScheduleDevice({device, schedule}) {
	return (
		<tr>
			<td colSpan={2}>
				<div className={"d-flex justify-content-between"}>
					<div>
						<DeviceText device={{
							...device,
							time: device.realTime
						}} />
					</div>
					{device.dynamic ? <ShowGraphModal device={device} schedule={schedule}/>:null}
				</div>
			</td>
		</tr>
	);
};
