import useSettings from "../../../hooks/useSettings";
import {useEffect, useState} from "react";
import Spinner from "../../Spinner";
import Error from "../../Alerts/Error";
import Warning from "../../Alerts/Warning";

export default function Settings() {
	const {settings, loading, errors, setErrors, warnings} = useSettings();
	const [maxCapacity, setMaxCapacity] = useState("");
	// const [desiredTemperature, setDesiredTemperature] = useState("");
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");

	const [updateLoading, setUpdateLoading] = useState(false);

	useEffect(() => {
		setMaxCapacity(settings.maxCapacity);
	}, [settings.maxCapacity]);
/*	useEffect(() => {
		setDesiredTemperature(settings.desiredTemperature);
	}, [settings.desiredTemperature]);*/
	useEffect(() => {
		setLatitude(settings.latitude);
	}, [settings.latitude]);
	useEffect(() => {
		setLongitude(settings.longitude);
	}, [settings.longitude]);

	async function onSubmit(evt) {
		evt.preventDefault();

		try {
			setErrors([]);
			setUpdateLoading(true);
			const response = await fetch(process.env.REACT_APP_ENDPOINT + "/settings", {
				method: "PUT",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({maxCapacity, /*desiredTemperature,*/ latitude, longitude})
			});
			if (response.status !== 204) {
				setErrors(["Wrong status code"]);
			}
		}
		catch (e) {
			console.error(e);
			setErrors(["Failed to connect to the server."]);
		}
		finally {
			setUpdateLoading(false);
		}
	}

	return (
		<form onSubmit={onSubmit} className={"border shadow-sm rounded p-2 bg-white"}>
			{loading && <Spinner />}
			<div className={"mt-2"}>
				{errors.map((value, index) => {
					return <Error key={index} message={value} />
				})}
				{warnings.map((value, index) => {
					return <Warning key={index} message={value} />
				})}
			</div>
			<div className="mb-3">
				<label htmlFor="maxCapacity" className="form-label">Max capacity (kW):</label>
				<input type="number" min={2} max={15} step={0.1} className="form-control" id="maxCapacity" value={maxCapacity} required={true} onChange={(evt) => {
					setMaxCapacity(evt.target.value);
				}} />
			</div>
			{/*<div className="mb-3">
				<label htmlFor="desiredTemperature" className="form-label">Desired temperature (°C):</label>
				<input type="number" min={10} max={30} step={0.1} className="form-control" id="desiredTemperature" value={desiredTemperature} required={true} onChange={(evt) => {
					setDesiredTemperature(evt.target.value);
				}} />
			</div>*/}
			<div className="row mb-3">
				<div className="col">
					<label htmlFor="latitude" className="form-label">Latitude:</label>
					<input type="number" className="form-control" id="desiredTemperature" value={latitude} required={true} onChange={(evt) => {
						setLatitude(evt.target.value);
					}} />
				</div>
				<div className="col">
					<label htmlFor="longitude" className="form-label">Longitude:</label>
					<input type="number"  className="form-control" id="longitude" value={longitude} required={true} onChange={(evt) => {
						setLongitude(evt.target.value);
					}} />
				</div>
			</div>
			<button className={"btn btn-warning w-100"} type={"submit"} disabled={updateLoading}>{updateLoading ? <Spinner />: 'Save'}</button>
		</form>
	);
}
