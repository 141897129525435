import DeviceInputs from "../DeviceInputs";
import {useContext, useState} from "react";
import {devicesContext} from "../../../../context/devicesContext";
import Error from "../../../Alerts/Error";

export default function EditDevice({device: initialDevice}) {
	const [device, setDevice] = useState(initialDevice);
	const [errors, setErrors] = useState([]);

	const {updateDevice} = useContext(devicesContext);

	async function onSubmit(evt) {
		evt.preventDefault();

		try {
			setErrors([]);
			const response = await fetch(process.env.REACT_APP_ENDPOINT + `/devices/${initialDevice.id}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(device)
			});
			if (response.status === 200) {
				const {device} = await response.json();
				updateDevice(device);
			}
			else if (response.status === 421) {
				const {errors} = await response.json();
				setErrors(errors);
			}
			else {
				setErrors(["Wrong status code"]);
			}
		}
		catch (e) {
			console.error(e);
			setErrors(["Failed to connect to the server."]);
		}
	}
	return (
		<>
			<button type="button" className="btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target={"#editDeviceModal" + device.id}>
				Edit
			</button>

			<div className="modal fade" id={"editDeviceModal" + device.id} tabIndex="-1" aria-labelledby={"editDeviceModalLabel" + device.id} aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<form onSubmit={onSubmit} className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id={"editDeviceModalLabel" + device.id}>Edit {device.name}</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<div className={"mt-2"}>
								{errors.map((value, index) => {
									return <Error key={index} message={value} />
								})}
							</div>
							<DeviceInputs device={device} setDevice={setDevice}/>
						</div>
						<div className="modal-footer d-flex justify-content-between">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" className="btn btn-warning">Save</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};
