import {Chart} from "react-chartjs-2";
import 'chart.js/auto';

export default function TimeGraph({point2, point1, averageTemperature}) {
	const xValues = [];
	const yValues = [];

	for (let i = -10; i <= point1.x; i++) {
		xValues.push(i);
		yValues.push(Math.ceil(((point2.y - point1.y) / (point2.x - point1.x)) * (i - point1.x) + point1.y));
		if (averageTemperature >= i && averageTemperature <= i + 1) {
			xValues.push(averageTemperature);
			yValues.push(null);
		}
	}

	const data = {
		labels: xValues,
		datasets: [{
			label: "Heating time (minutes) in function of outside temperature",
			borderWidth: 1,
			pointBorderWidth: 0,
			data: yValues,
		}]
	};

	if (averageTemperature) {
		console.log(averageTemperature, Math.ceil(((point2.y - point1.y) / (point2.x - point1.x)) * (averageTemperature - point1.x) + point1.y));

		data.datasets.push({
			label: "Accurate time",
			data: [
				{x:averageTemperature, y:Math.ceil(((point2.y - point1.y) / (point2.x - point1.x)) * (averageTemperature - point1.x) + point1.y)},
			],
			borderWidth: 5
		});
	}

	// return null;
	return <Chart type={"line"} data={data} options={{
		scales: {
			y: {
				title: {
					display: true,
					text: "Time (minutes)"
				},
			},
			x: {
				title: {
					display: true,
					text: "Average outside temperature (°C)"
				}
			}
		}
	}}/>;
};
